import React, { Component } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import queryString from "query-string";
import * as constants from "./constants";
import "./App.css";
import logo from "./logo.png";

const parsed = queryString.parse(window.location.search) || {};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            limit: props.countLimit,
            page: 0,
            rSelected: 1,
            AUTO_REFRESH_TIME: 300000 //  5 Minutes
        };
    }

    async componentDidMount() {
        if (isIOS) {
            this.handleIos();
        } else if (isAndroid) {
            this.handleAndroid();
        } else {
            console.warn(" :::::::::: NO IOS OR ANDROID ::::::::::");
        }
    }

    handleIos = () => {
        // setTimeout(() => {
        //     window.location.replace(constants.iosStore);
        // }, 5);
        const appUrl = `${constants.iosApp}${parsed.token}&route=track`;
        this.setState({ appUrl });
        window.location.replace(appUrl);
    };

    handleAndroid = () => {
        // setTimeout(() => {
        //     window.location.replace(constants.playStore);
        // }, 5);
        // const androidUrl = `https://drivertracker.page.link/?link=https://drivertracker.com/track&slug=${parsed.token}&apn=com.asritsolutions.driverTracker`
        // window.location.replace(androidUrl);

        const appUrl = `${constants.playApp}${parsed.token}&route=track`;
        // const appUrl = `com.asritsolutions.dispatch://`;
        this.setState({ appUrl });
        window.location.replace(appUrl);
    };

    componentWillUnmount() {}

    render() {
        const { appUrl } = this.state;
        let launchUrl = "https://osrit.com";
        if (isIOS) {
            launchUrl = constants.iosStore;
        } else if (isAndroid) {
            launchUrl = constants.playStore;
        }

        return (
            <div className="App">
                <img src={logo} className="App-logo" alt="logo" />
                <div>Osrit Dispatch Software.</div>
                {appUrl && <div>{appUrl}</div>}
                <a href={launchUrl} className="App-launch">
                    {"Get App"}
                </a>
                <div>
                    <a
                        className="App-link"
                        href="https://osrit.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Learn More
                    </a>
                </div>
                <div className="App-small">RELEASE 2.0</div>
            </div>
        );
    }
}

export default App;
